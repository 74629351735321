<template>
  <div class="views-home">
    <div class="views-home-main M">
      <div class="heading">钢铁行业“双碳”技能竞赛</div>
      <div class="description">
        培养钢铁行业具备碳排放核算与分析能力的人才队伍，弘扬劳模精神和工匠精神，推动钢铁企业向高质量、可持续发展迈进，为实现碳达峰、碳中和目标贡献力量
      </div>
      <div class="list">
        <div class="card card-1">
          <div class="title">组织碳计算模块</div>
          <div class="subtitle">企业范围内的温室气体核算</div>
          <el-button
            class="enter-btn"
            :loading="skipOrganizeLoading"
            @click="skipOrganize()"
            >进入模块</el-button
          >
        </div>
        <div class="card card-2">
          <div class="title">产品碳计算模块</div>
          <div class="subtitle">产品全生命周期温室气体核算</div>
          <el-button
            class="enter-btn"
            :loading="skipProductLoading"
            @click="skipProduct()"
            >进入模块</el-button
          >
        </div>
        <div class="card card-3">
          <div class="title">答题评分模块</div>
          <div class="subtitle">稳定高效的无纸化考试系统</div>
          <div class="enter-btn" @click="toQuestions()">进入模块</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      skipOrganizeLoading: false,
      skipProductLoading: false,
    };
  },
  methods: {
    skipOrganize() {
      this.skipOrganizeLoading = true;
      this.$store
        .dispatch('updateExamsPaperStatus')
        .then(res => {
          // let host = 'https://saas-uat4.ecdigit.cn';
          let host = location.protocol + '//' + location.hostname;
          window.open(
            `${host}/ghg?token=${this.$store.state.token}&jumpUrl=${host}/examspaper/#/login`,
            '_blank'
          );
        })
        .finally(() => {
          this.skipOrganizeLoading = false;
        });
    },
    skipProduct() {
      this.skipProductLoading = true;
      this.$store
        .dispatch('updateExamsPaperStatus')
        .then(res => {
          let host = location.protocol + '//' + location.hostname;
          window.open(
            `${process.env.VUE_APP_LCAURL}?token=${this.$store.state.token}&jumpUrl=${host}/examspaper/#/login`,
            '_blank'
          );
        })
        .finally(() => {
          this.skipProductLoading = false;
        });
    },
    toQuestions() {
      this.openUrl({
        name: 'answer',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.views-home {
  flex: 1;
  .views-home-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 55px;
    margin-top: 130px;
    .heading {
      font-family: PingFang SC, PingFang SC;
      font-weight: 800;
      font-size: 52px;
      color: #ffffff;
      line-height: 52px;
    }
    .description {
      margin-top: 40px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #ffffff;
      line-height: 18px;
    }
    .list {
      margin-top: 100px;
      display: flex;
      .card-1 {
        background-image: url('~@/assets/img/home-bg-1.png');
      }
      .card-2 {
        background-image: url('~@/assets/img/home-bg-2.png');
      }
      .card-3 {
        background-image: url('~@/assets/img/home-bg-3.png');
      }
      .card {
        width: 260px;
        height: 360px;
        margin: 0 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-size: 100% 100%;
        .title {
          margin-top: 50px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 800;
          font-size: 22px;
          color: #ffffff;
          line-height: 22px;
        }
        .subtitle {
          margin-top: 20px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #ffffff;
          line-height: 16px;
        }
        .enter-btn {
          cursor: pointer;
          margin-top: 20px;
          width: 124px;
          height: 34px;
          background: rgba(255, 255, 255, 0.1);
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #ffffff;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #ffffff;
          line-height: 16px;
        }
      }
    }
  }
}
</style>
